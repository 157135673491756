import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: '#bbb7a9',
                btn_text: '#661a00',
                s4u_bg: '#fff2e6',
                m4u_text: '#006666',
                m4u_tertiary: '#b6c1c7',
                m4u_text_button: '#006666',
                message_background: '#FFFDE7',
                m4u_teal: '#00cccc',
                m4u_light_grey: 'c4c4c4'
            },
            dark: {
                primary: '#FFCB47',
                btn_text: '#661a00',
                s4u_bg: '#fff2e6',
                m4u_text: '#250E62',
                m4u_tertiary: '#E0F7FA',
                message_background: '#FFFDE7',
            }
        }
        
    }
});
