<template>
  <v-app :style="cssProps">
    <v-app-bar
      app
      color="m4u_light_grey"
      dark
    >
      <div class="d-flex align-center">
        <v-img src="@/assets/logo.png" max-height="3em" max-width="3em"></v-img>
        <h2 class="m4u_text--text ml-2">Memories For Us</h2>
        <!-- <v-img
          alt="Memories For Us Logo"
          class="mr-2"
          contain
          max-width="10em"
          :src="require('./assets/logo.png')"
          transition="scale-transition"  /> -->
      </div>

      <v-spacer></v-spacer>

      <v-btn
        href="https://login.memoriesforus.com"
        target="_blank"
        text
        x-large
      >
        <span class="mr-2 m4u_text--text">Login</span>
        <v-icon class="m4u_text--text">mdi-open-in-new</v-icon>
      </v-btn>
    </v-app-bar>

    <v-main>
      <Home/>
    </v-main>
  </v-app>
</template>

<script>
import Home from './components/Home';

export default {
  name: 'App',

  components: {
    Home,
  },

  data: () => ({
    //
    cssProps: {
          backgroundImage: `url(${require('@/assets/background.jpg')})`
        }
  }),
};
</script>
<style scoped>

</style>