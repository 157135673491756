<template>
<div>
<v-layout justify-center>
    <v-card :max-width ="$vuetify.breakpoint.smAndDown ? '85vw' : '85vw'" class="text-center m4u_tertiary m4u_text--text mb-8 mt-8">
        <!-- <v-img
          :src="require('../assets/logo.png')"
          class="my-3"
          contain
          height="200"
        /> -->

        <h1 class="mb-3 ">
            Keeping people connected across generations.
        </h1>

    <div class="descriptionPara">
      <v-layout>
      <v-card width=20% class="m4u_tertiary">
      <div width="100%" class="imageDiv">
        <v-img max-width="100%" max-height="10em" src="../assets/familymoments.jpg" class="imageClass"></v-img>
      </div>
        <p class="m4u_text--text left--text">
            <strong>Save and share family moments</strong></p>
      </v-card>
      <v-card width=20% class="m4u_tertiary">
      <div width="100%" class="imageDiv">
         <v-img max-width="100%" max-height="10em" src="../assets/generations.jpg" class="imageClass"></v-img>
         </div>
        <p class="m4u_text--text left--text">
            <strong>Capture stories from earlier generations</strong></p>
      </v-card>
      <v-card width=20% class="m4u_tertiary">
        <div width="100%" class="imageDiv">
        <v-img max-width="100%" max-height="10em" src="../assets/intouch.jpg" class="imageClass"></v-img>   
        </div>
        <p class="m4u_text--text left--text">
            <strong> Stay in touch with the people you care about most</strong></p>
      </v-card>
      <v-card width=20% class="m4u_tertiary">
        <div width="100%" class="imageDiv">
        <v-img max-width="100%" max-height="10em" src="../assets/pictures.jpg" class="imageClass"></v-img> 
        </div>
        <p class="m4u_text--text left--text ">
            <strong> Save pictures, videos, recipes, journal, record traditions</strong></p>
      </v-card>
      <v-card width=20% class="m4u_tertiary">
        <div width="100%" class="imageDiv">
        <v-img max-width="100%" max-height="10em" src="../assets/fakefact_not.jpg" class="imageClass"></v-img> 
        </div>
        <p class="m4u_text--text left--text">
            <strong> Just your memories, none of the memes, articles or other noise from the internet</strong></p>
      </v-card>       
      </v-layout>

         <h4 class="mt-4 descriptionPara">We make it easy to save, share, collect and find those memories. </h4>

                 <p class="mb-3 descriptionPara mt-5">
           <strong> We are just launching our app. For now, membership is by invitation only. If you 
            want to be notified when you can sign up, please send us an email at </strong><a href = "mailto: info@memoriesforus.com">
              <strong class="m4u_teal--text">info@memoriesforus.com</strong></a>. 
        </p>

    </div>


    </v-card>
</v-layout>
</div>
</template>

<script>
  export default {
    name: 'Home',

    data: () => ({
      
    }),
  }
</script>

<style scoped>

.benefits {
  align-content: center;
}
.descriptionPara {
  width: 80%;
  font-size: 1.25em;
  margin: auto;
}
.imageClass {
  object-fit: cover;
}

.imageDiv {
  width: 100%;
  height: 10em;
  object-fit: cover;
}
.list {
  list-style-type: none; /* Remove bullets */
  padding: 0; /* Remove padding */
  margin: 0; /* Remove margins */
}
</style>